/* @import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap'); */
@font-face {
  font-family: "alagard";
  src: local("alagard"), url("alagard.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-image: linear-gradient(
    to top,
    rgba(228,120,164,255),
    rgba(22,41,108,255)
  );

  background-size: cover;
  @media (max-width: 600px) {
    .body {
      background-size: 300px 100px;
    }
  }
}

a {
  color: black;
}

h1 {
  font-family: alagard;
  color: rgb(0, 0, 0);
  text-shadow: -5px 0 rgb(255, 255, 255), 0 1px rgb(255, 255, 255),
  1px 0 rgb(255, 255, 255), 0 -1px rgb(255, 255, 255);
}

p,
a,
h3 {
  font-family: alagard;
  color: rgb(0, 0, 0);
  text-shadow: -1px 0 rgb(255, 255, 255), 0 1px rgb(255, 255, 255),
    1px 0 rgb(255, 255, 255), 0 -1px rgb(255, 255, 255);
  
}

fade-in-text {
  font-family: alagard;
  font-size: 2rem;
  animation: fadeIn 5s;
  /* sombra de 1 pixel por lado para que no se pierdan las letras */
  text-shadow: -1px 0 rgb(255, 255, 255), 0 1px rgb(255, 255, 255),
    1px 0 rgb(255, 255, 255), 0 -1px rgb(255, 255, 255);
}
